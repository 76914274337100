<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Codigo agente -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                clearable
                hint="Código del agente"
                persistent-hint
                v-mask="'#########'"
                label="Agente de cuenta"
                :rules="rules.required"
                v-model="codigoAgenteCta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Boton buscar -->
            <v-col cols="12" sm="6" md="2" class="py-0">
              <v-btn
                color="primary"
                :disabled="!searchAgenteBtn"
                @click="findAgenteCta()"
              >
                Buscar
              </v-btn>
            </v-col>
            <!-- Boton buscar por nombre o dni -->
            <v-col cols="12" sm="6" md="4" class="py-0 pl-8">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    absolute
                    fab
                    small
                    color="primary"
                    :disabled="searchAgenteBtn"
                    @click="toggleModalBusquedaAgecta()"
                  >
                    <v-icon>{{ searchIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Buscar por otro</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4" sm="6" md="6" class="py-0">
              <v-autocomplete
                outlined
                dense
                v-model="grupoFamiliarSelected"
                :disabled="conceptoADevengarXPeriodo != null"
                :class="
                  conceptoADevengarXPeriodo != null
                    ? 'theme--light.v-input input'
                    : ''
                "
                label="Titular del grupo"
                item-text="value"
                item-value="id"
                autocomplete="on"
                :rules="rules.required"
                :items="gruposFamiliares"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="4" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="conceptoSelected"
                :items="conceptos"
                label="Concepto"
                item-text="value"
                :rules="rules.required"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4" sm="6" md="4" class="py-0">
              <currency-input
                label="Importe"
                v-model="importe"
                oulined
                dense="false"
                outlined="false"
                :options="currencyOptions"
                :rules="rules.required"
              >
              </currency-input>
            </v-col>
            <v-col cols="4" sm="6" md="4" class="py-0">
              <v-text-field
                ref="periodoDesde"
                v-model="periodoDesde"
                label="Período desde"
                outlined
                dense
                v-mask="'######'"
                autocomplete="not"
                :rules="
                  rules.required.concat(
                    rules.periodoYyyyMm,
                    rules.validDateRange(periodoDesde, periodoHasta)
                  )
                "
                hint="Formato AAAAMM"
                persistent-hint
              ></v-text-field
            ></v-col>
            <v-col cols="4" sm="6" md="4" class="py-0">
              <v-text-field
                ref="periodoHasta"
                v-model="periodoHasta"
                label="Período hasta"
                outlined
                dense
                v-mask="'######'"
                autocomplete="not"
                :rules="
                  rules.required.concat(
                    rules.periodoYyyyMm,
                    rules.validDateRange(periodoDesde, periodoHasta)
                  )
                "
                hint="Formato AAAAMM"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeModal"> Cancelar </v-btn>
            <v-btn
              type="submit"
              :disabled="!isFormValid"
              form="form"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <v-dialog
        v-model="modalBusquedaAgecta"
        v-if="modalBusquedaAgecta"
        @keydown.esc="toggleModalBusquedaAgecta"
        max-width="65%"
        persistent
      >
        <BuscarAgenteCuenta
          @toggleModalBusquedaAgecta="toggleModalBusquedaAgecta"
          @findAgenteCta="findAgenteCta"
        />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import BuscarAgenteCuenta from "@/components/modules/cuotas/agenteCuenta/BuscarAgenteCuenta.vue";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "EditDevengarXPeriodo",
  components: { PageHeader, BuscarAgenteCuenta, CurrencyInput },
  directives: { mask },
  data: vm => ({
    codigoAgenteCta: null,
    formEditTitle: "Editar concepto a devengar por período",
    searchAgenteBtn: false,
    searchIcon: enums.icons.SEARCH,
    isFormValid: false,
    rules: rules,
    nombreAgenteCta: null,
    modalBusquedaAgecta: false,
    gruposFamiliares: [],
    grupoFamiliarSelected: null,
    conceptos: [],
    conceptoSelected: null,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: -999999999999999.99,
        max: 999999999999999.99
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    periodoDesde: null,
    periodoHasta: null,
    importe: null
  }),
  props: {
    conceptoADevengarXPeriodo: {
      type: Object,
      required: false
    }
  },
  created() {
    if (this.conceptoADevengarXPeriodo != null) {
      this.setConcepto();
    } else {
      this.newConcepto();
    }
    this.setSelects();
  },
  watch: {
    codigoAgenteCta() {
      if (
        this.codigoAgenteCta != null &&
        this.codigoAgenteCta != undefined &&
        this.codigoAgenteCta != ""
      ) {
        this.searchAgenteBtn = true;
      } else {
        this.searchAgenteBtn = false;
        this.gruposFamiliares = [];
        this.grupoFamiliarSelected = null;
      }
    }
  },
  methods: {
    ...mapActions({
      getAgenteCuentaByCodigo: "afiliaciones/getAgenteCuentaByCodigo",
      getTitularesByAgecta: "devengamientos/getTitularesByAgecta",
      getConceptos: "devengamientos/getConceptos",
      saveConceptosDevengarPorPeriodo:
        "devengamientos/saveConceptosDevengarPorPeriodo",
      setAlert: "user/setAlert"
    }),
    setConcepto() {
      this.codigoAgenteCta = this.conceptoADevengarXPeriodo.agectaId;
      this.importe = this.conceptoADevengarXPeriodo.importe;
      this.periodoHasta = this.conceptoADevengarXPeriodo.periHasta;
      this.periodoDesde = this.conceptoADevengarXPeriodo.periDesde;
      this.conceptoSelected = this.conceptoADevengarXPeriodo.conceptoId;
      this.setTitularGrupoFamiliar();

      // por sí el grupo familiar no se encuentra más relacionado al agente de cuenta
      const findGrupoFamiliar = this.gruposFamiliares.find(
        x => x.id == this.conceptoADevengarXPeriodo.benGrId
      );
      if (findGrupoFamiliar != null)
        this.grupoFamiliarSelected = this.conceptoADevengarXPeriodo.benGrId;
    },
    toggleModalBusquedaAgecta() {
      this.modalBusquedaAgecta = !this.modalBusquedaAgecta;
    },
    newConcepto() {
      this.formEditTitle = "Nuevo concepto a devengar por período";
    },
    async findAgenteCta(agenteSelected) {
      if (agenteSelected == undefined || agenteSelected == {}) {
        const response = await this.getAgenteCuentaByCodigo({
          codigo: this.codigoAgenteCta,
          nombre: "",
          dni: "",
          cuit: ""
        });
        this.agente = response[0];
        if (this.agente != null) {
          this.codigoAgenteCta = this.agente.agenteId;
          this.nombreAgenteCta = this.agente.nombre;
          this.setAlert({
            type: "success",
            message: `El agente ${this.agente.nombre} ha sido seleccionado.`
          });
          this.setTitularGrupoFamiliar();
        }
      } else {
        this.agente = agenteSelected;
        this.codigoAgenteCta = agenteSelected.agenteId;
        this.nombreAgenteCta = agenteSelected.nombre;
        this.setAlert({
          type: "success",
          message: `El agente ${agenteSelected.nombre} ha sido seleccionado.`
        });
        this.setTitularGrupoFamiliar();
      }
    },
    async saveEdit() {
      if (this.importe > 99999999.99 || this.importe < -99999999.99) {
        this.setAlert({
          type: "alert",
          message:
            "El importe no puede ser menor a -99.999.999,99 o superior a 99.999.999,99"
        });
        return;
      }
      const data = {
        adiPorPeriId:
          this.conceptoADevengarXPeriodo != null
            ? this.conceptoADevengarXPeriodo.adiPorPeriId
            : null,
        agectaId: this.codigoAgenteCta,
        periDesde: this.periodoDesde,
        periHasta: this.periodoHasta,
        importe: this.importe,
        conceptoId: this.conceptoSelected,
        benGrId: this.grupoFamiliarSelected
      };
      const res = await this.saveConceptosDevengarPorPeriodo(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    async setSelects() {
      const conceptos = await this.getConceptos();
      this.conceptos = conceptos;
    },
    async setTitularGrupoFamiliar() {
      const response = await this.getTitularesByAgecta(this.codigoAgenteCta);
      this.gruposFamiliares = response;
      if (
        this.gruposFamiliares.length === 1 &&
        this.conceptoADevengarXPeriodo == null
      )
        this.grupoFamiliarSelected = this.gruposFamiliares[0].id;

      // por sí el grupo familiar no se encuentra más relacionado al agente de cuenta
      if (this.conceptoADevengarXPeriodo != null) {
        const findGrupoFamiliar = this.gruposFamiliares.find(
          x => x.id == this.conceptoADevengarXPeriodo.benGrId
        );
        if (findGrupoFamiliar != null)
          this.grupoFamiliarSelected = this.conceptoADevengarXPeriodo.benGrId;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep .v-input__slot label {
  margin-bottom: 0% !important;
}
</style>
