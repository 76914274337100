<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <!-- formulario para filtrar -->
                <v-row>
                  <!-- Codigo agente -->
                  <v-col cols="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      v-mask="'#########'"
                      label="Código de agente"
                      v-model="codigoAgenteCta"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Boton buscar por nombre o dni -->
                  <v-col cols="1" class="py-0">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          absolute
                          fab
                          small
                          color="primary"
                          :disabled="searchAgenteBtn"
                          @click="modalBusquedaAgecta = true"
                        >
                          <v-icon>{{ searchIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Buscar agente de cuenta</span>
                    </v-tooltip>
                  </v-col>
                  <!-- Titular de grupo -->
                  <v-col cols="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      clearable
                      dense
                      label="N° de grupo familiar"
                      v-model.trim="nroGrupo"
                      autocomplete="off"
                      v-mask="'##########'"
                    ></v-text-field>
                  </v-col>
                  <!-- Boton buscar por nombre o dni -->
                  <v-col cols="1" class="py-0">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          absolute
                          fab
                          small
                          color="primary"
                          @click="modalBusquedaTitularGrupo = true"
                        >
                          <v-icon>{{ searchIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Buscar titular del grupo</span>
                    </v-tooltip>
                  </v-col>
                  <!-- Período -->
                  <v-col cols="2" class="py-0">
                    <v-text-field
                      ref="periodoD"
                      v-model="periodo"
                      label="Período"
                      outlined
                      dense
                      clearable
                      autocomplete="not"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      :rules="
                        (nroGrupo == null || nroGrupo == '') &&
                        (codigoAgenteCta == null || codigoAgenteCta == '') &&
                        conceptoSelected == null &&
                        userSelected == null &&
                        fechaSelected == null &&
                        fecha == null
                          ? rules.required.concat(rules.periodoYyyyMm)
                          : []
                      "
                      hint="Formato AAAAMM"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Concepto -->
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      v-model="conceptoSelected"
                      :items="conceptos"
                      label="Concepto"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Usuario -->
                  <v-col cols="3" class="py-0">
                    <v-autocomplete
                      outlined
                      clearable
                      dense
                      v-model="userSelected"
                      label="Usuario de alta"
                      item-text="value"
                      item-value="value"
                      autocomplete="on"
                      :items="usuarios"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Fecha de alta -->
                  <v-col cols="3" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menuFecha"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaSelected"
                          label="Fecha de alta"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          clearable
                          hint="Formato DD/MM/AAAA"
                          @blur="fecha = parseDateToIso(fechaSelected)"
                          :rules="fechaSelected != null ? rules.validDate : []"
                          outlined
                          dense
                          v-mask="'##/##/####'"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fecha"
                        no-title
                        @change="fechaSelected = formatDate(fecha)"
                        @input="menuFecha = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- Aplicar y limpiar -->
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-row justify="end">
            <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
              <v-btn-toggle dense v-model="text" color="primary" group>
                <v-btn value="POR ÚNICA VEZ" @click="banUser(1)">
                  Por única vez
                </v-btn>
                <v-btn value="APORTES" @click="banUser(2)"> Por período </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <div v-if="mostrarTableUnicaVez">
            <v-data-table
              class="elevation-1"
              :headers="conceptosPorUnicaVezHeaders"
              :items="itemsPorUnicaVez"
              item-key="interesesACobrarId"
              :search="searchPorUnicaVez"
              :expanded.sync="expanded"
              show-expand
              :loading="isLoading"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="4" :md="showHelp ? 2 : 3" sm="2">
                      <v-text-field
                        v-model="searchPorUnicaVez"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :md="showHelp ? 2 : 2" sm="2">
                      <v-text-field
                        outlined
                        dense
                        disabled
                        class="theme--light.v-input input right-input"
                        label="Importe por única vez"
                        v-model="importeTotalPorUnicaVez"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :md="showHelp ? 2 : 2" sm="2">
                      <v-btn
                        color="primary"
                        small
                        outlined
                        class="no-upper-case to-right"
                        v-if="canDeleteAll"
                        v-model="deleteAllConceptosBtn"
                        @click="deleteAllConceptosXUnicaVez()"
                        :disabled="itemsPorUnicaVez.length === 0"
                      >
                        Eliminar todos
                      </v-btn>
                    </v-col>
                    <v-col cols="3" :md="showHelp ? 5 : 4" sm="2">
                      <v-btn
                        color="primary"
                        class="no-upper-case"
                        v-if="canGoToProcesosImportacion"
                        @click="goToProcesosImportacion()"
                      >
                        Procesos de importación
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="2"
                      :md="showHelp ? 1 : 1"
                      sm="1"
                      align="end"
                      v-if="canCreate"
                    >
                      <v-btn
                        color="primary"
                        @click="openModalConceptXUnicaVez()"
                        class="to-right"
                      >
                        Nuevo
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left v-if="canEdit">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="openModalConceptXUnicaVez(item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span v-if="item.procesado == ''">Editar concepto</span>
                  <span v-else
                    >El concepto se encuentra procesado. No se puede
                    modificar.</span
                  >
                </v-tooltip>
                <v-tooltip left v-if="canDelete">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="deleteConceptoXUnicaVez(item)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span v-if="item.procesado == ''">Eliminar concepto</span>
                  <span v-else
                    >El concepto se encuentra procesado. No se puede
                    eliminar.</span
                  >
                </v-tooltip>
              </template>
              <template
                v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="handleExpansion(item, isExpanded)"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      >{{
                        isExpanded ? "mdi-account-check" : "mdi-account"
                      }}</v-icon
                    >
                  </template>
                  <span>Usuarios</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <strong>Fecha alta:</strong>
                  {{ item.fechaAlta }}
                  <strong style="padding-left: 80px">Usuario alta:</strong>
                  {{ item.usuarioAlta }}
                  <strong style="padding-left: 80px"
                    >Fecha modificación:</strong
                  >
                  {{ item.fechaModi }}
                  <strong style="padding-left: 80px"
                    >Usuario modificación:</strong
                  >
                  {{ item.usuarioModi }}
                </td>
              </template>
              <template v-slot:[`item.importe`]="{ item }">
                <span>{{ addDecimals(item.importe) }}</span>
              </template>
            </v-data-table>
             <v-spacer></v-spacer>
            <v-tooltip left max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="to-right mt-4 py-4"
                  v-bind="attrs"
                  small
                  :disabled="itemsPorUnicaVez.length == 0"
                  outlined
                  @click="exportExcelXUnicaVez"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </div>
          <div v-if="mostrarTablePeriodo">
            <v-data-table
              class="elevation-1"
              :headers="conceptoXPeriodoheaders"
              :items="itemsPorPeriodo"
              :search="searchPorPeriodo"
              show-expand
              :expanded.sync="expanded"
              item-key="adiPorPeriId"
              :loading="isLoading"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="5" :md="showHelp ? 4 : 5" sm="2">
                      <v-text-field
                        v-model="searchPorPeriodo"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :md="showHelp ? 2 : 2" sm="2">
                      <v-text-field
                        outlined
                        dense
                        disabled
                        label="Importe por período"
                        v-model="importeTotalPorPeriodo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" :md="showHelp ? 4 : 4" sm="2">
                      <v-btn
                        color="primary"
                        class="no-upper-case to-right mr-5"
                        v-if="canGoToProcesosActualizacion"
                        @click="goToProcesosActualizacion()"
                      >
                        Procesos de actualización
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="2"
                      :md="showHelp ? 2 : 1"
                      sm="2"
                      align="end"
                      v-if="canCreate"
                    >
                      <v-btn
                        color="primary"
                        @click="openModalPorPeriodo()"
                        class="to-right"
                      >
                        Nuevo
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left v-if="canEdit">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="openModalPorPeriodo(item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar concepto</span>
                </v-tooltip>
                <v-tooltip left v-if="canDelete">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="deleteConceptoPorPeriodoModal(item.adiPorPeriId)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar concepto</span>
                </v-tooltip>
              </template>
              <template
                v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="handleExpansion(item, isExpanded)"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      >{{
                        isExpanded ? "mdi-account-check" : "mdi-account"
                      }}</v-icon
                    >
                  </template>
                  <span>Usuarios</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <strong>Fecha alta:</strong>
                  {{ item.fechaAlta }}
                  <strong style="padding-left: 80px">Usuario alta:</strong>
                  {{ item.usuarioAlta }}
                  <strong style="padding-left: 80px"
                    >Fecha modificación:</strong
                  >
                  {{ item.fechaModi }}
                  <strong style="padding-left: 80px"
                    >Usuario modificación:</strong
                  >
                  {{ item.usuarioModi }}
                </td>
              </template>
              <template v-slot:[`item.importe`]="{ item }">
                <span>{{ addDecimals(item.importe) }}</span>
              </template>
            </v-data-table>
             <v-spacer></v-spacer>
            <v-tooltip left max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="to-right mt-4 py-4"
                  v-bind="attrs"
                  small
                  :disabled="itemsPorPeriodo.length == 0"
                  outlined
                  @click="exportExcelXPeriodo"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </div>
           
          <v-dialog
            v-if="openModalEditDevengarUnicaVez"
            v-model="openModalEditDevengarUnicaVez"
            @keydown.esc="closeAndReload"
            max-width="60%"
            persistent
          >
            <EditDevengarUnicaVez
              :conceptoADevengarXUnicaVez="conceptoADevengarXUnicaVez"
              @closeAndReload="closeAndReload"
            ></EditDevengarUnicaVez>
          </v-dialog>
          <DeleteDialog
            :titleProp="titleDelete"
            :textDelete="textDelete"
            :openDelete.sync="showDeleteModal"
            :maxWidth="deleteWidth"
            @onDeleteItem="confirmDelete()"
          />
          <!-- Editar Concepto a devengar por periodo -->
          <v-dialog
            v-if="openModalEditXPeriodo"
            v-model="openModalEditXPeriodo"
            @keydown.esc="closeAndReload"
            max-width="60%"
            persistent
          >
            <EditDevengarXPeriodo
              :conceptoADevengarXPeriodo="conceptoADevengarXPeriodo"
              @closeAndReload="closeAndReload"
            ></EditDevengarXPeriodo>
          </v-dialog>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-8 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="modalBusquedaAgecta"
      v-if="modalBusquedaAgecta"
      @keydown.esc="toggleModalBusquedaAgecta"
      max-width="65%"
      persistent
    >
      <BuscarAgenteCuenta
        @toggleModalBusquedaAgecta="toggleModalBusquedaAgecta"
        @findAgenteCta="findAgenteCta"
      ></BuscarAgenteCuenta>
    </v-dialog>
    <v-dialog
      v-model="modalBusquedaTitularGrupo"
      v-if="modalBusquedaTitularGrupo"
      @keydown.esc="toggleModalBusquedaTitularGrupo"
      max-width="65%"
      persistent
    >
      <BuscarTitularGrupo
        @toggleModalBusquedaTitularGrupo="toggleModalBusquedaTitularGrupo"
        @findTitularGrupo="findTitularGrupo"
      ></BuscarTitularGrupo>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import FiltersSelected from "@/components/shared/FiltersSelected";
import EditDevengarXPeriodo from "@/views/modules/cuotas/devengamientos/EditDevengarXPeriodo.vue";
import EditDevengarUnicaVez from "@/views/modules/cuotas/devengamientos/EditDevengarUnicaVez.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import BuscarAgenteCuenta from "@/components/modules/cuotas/agenteCuenta/BuscarAgenteCuenta.vue";
import BuscarTitularGrupo from "@/components/modules/cuotas/agenteCuenta/BuscarTitularGrupo.vue";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "ConceptosADevengar",
  components: {
    PageHeader,
    EditDevengarXPeriodo,
    DeleteDialog,
    EditDevengarUnicaVez,
    FiltersSelected,
    BuscarAgenteCuenta,
    BuscarTitularGrupo,
    Ayuda,
    GoBackBtn
  },
  directives: { mask },
  data() {
    return {
      title: enums.titles.CONCEPTOS_A_DEVENGAR,
      calendarIcon: enums.icons.CALENDAR,
      showFiltersSelected: false,
      deleteIcon: enums.icons.DELETE,
      searchIcon: enums.icons.SEARCH,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      fechaSelected: null,
      conceptoSelected: null,
      menuFecha: false,
      showExpand: false,
      routeToGo: "ImportesADevengar",
      showHelp: false,
      optionCode: enums.webSiteOptions.CONCEPTOS_A_DEVENGAR,
      showIcon: true,
      fecha: null,
      isFormValid: false,
      text: "POR ÚNICA VEZ",
      searchAgenteBtn: false,
      showFilters: true,
      userSelected: null,
      usuarios: [],
      periodo: null,
      importeTotalPorUnicaVez: 0,
      importeTotalPorPeriodo: 0,
      importeTotal: 0,
      showDeleteModal: false,
      nroGrupo: null,
      tiposAgentes: [],
      tipoAgenteSelected: null,
      filtersApplied: [],
      searchPorPeriodo: null,
      searchPorUnicaVez: null,
      isLoading: false,
      modalBusquedaAgecta: false,
      modalBusquedaTitularGrupo: false,
      codigoAgenteCta: null,
      tab: null,
      mostrarTableUnicaVez: true,
      mostrarTablePeriodo: false,
      editIcon: enums.icons.EDIT,
      titleDelete: "¿Eliminar concepto?",
      idToDelete: null,
      idsToDelete: [],
      deleteModalDevengarUnicaVez: false,
      showDeleteModalPeriodo: false,
      itemsPorUnicaVez: [],
      itemsPorPeriodo: [],
      conceptos: [],
      usuario: null,
      deleteAllConceptosBtn: false,
      rules: rules,
      conceptosPorUnicaVezHeaders: [
        {
          text: "Agente de cuenta",
          align: "start",
          value: "agectaNom"
        },
        {
          text: "N° de grupo familiar",
          value: "benGrId",
          sortable: false
        },
        {
          text: "Concepto",
          value: "conceptoNom",
          sortable: false
        },
        {
          text: "Importe",
          value: "importe",
          align: "end",
          sortable: false
        },
        {
          text: "Período",
          value: "periodo",
          sortable: false
        },
        {
          text: "Período a devengar",
          value: "periodoDev",
          sortable: false
        },
        {
          text: "Comprobante orígen",
          value: "comprobanteOrigenValue",
          sortable: false
        },
        {
          text: "Procesado en comprobante",
          value: "procesado",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          align: "end"
        }
      ],
      conceptoXPeriodoheaders: [
        {
          text: "Agente de cuenta",
          value: "agectaNom",
          sortable: true
        },
        {
          text: "N° de grupo familiar",
          value: "benGrId",
          sortable: false
        },
        {
          text: "Concepto",
          sortable: false,
          value: "conceptoNom"
        },
        {
          text: "Importe",
          sortable: false,
          align: "end",
          value: "importe"
        },
        {
          text: "Período desde",
          sortable: false,
          value: "periDesde"
        },
        {
          text: "Período hasta",
          sortable: false,
          value: "periHasta"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          align: "end"
        }
      ],
      allowedActions: null,
      canEdit: false,
      canDelete: false,
      canDeleteAll: false,
      canCreate: false,
      canGoToProcesosImportacion: false,
      canGoToProcesosActualizacion: false,
      openModalEditXPeriodo: false,
      openModalEditDevengarUnicaVez: false,
      expanded: [],
      deleteModalConceptoPorPeriodo: false,
      conceptoADevengarXUnicaVez: null,
      conceptoADevengarXPeriodo: null,
      deleteWidth: "25%",
      textDelete: ""
    };
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setSelects();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  watch: {
    itemsPorUnicaVez(val) {
      if (val.length > 0) {
        this.importeTotalPorUnicaVez = this.itemsPorUnicaVez
          .map(x => x.importe)
          .reduce((a, b) => a + b, 0);
        this.importeTotalPorUnicaVez = this.addDecimals(
          this.importeTotalPorUnicaVez
        );
      } else {
        this.importeTotalPorUnicaVez = 0;
      }
    },
    itemsPorPeriodo(val) {
      if (val.length > 0) {
        this.importeTotalPorPeriodo = this.itemsPorPeriodo
          .map(x => x.importe)
          .reduce((a, b) => a + b, 0);
        this.importeTotalPorPeriodo = this.addDecimals(
          this.importeTotalPorPeriodo
        );
      } else {
        this.importeTotalPorPeriodo = 0;
      }
    }
  },
  methods: {
    ...mapActions({
      getUsuarios: "devengamientos/getUsuarios",
      deleteConceptosXUnicaVez: "devengamientos/deleteConceptosXUnicaVez",
      deleteConceptoPorPeriodo: "devengamientos/deleteConceptoPorPeriodo",
      fetchConceptosADevengarByParams:
        "devengamientos/fetchConceptosADevengarByParams",
      getConceptos: "devengamientos/getConceptos",
      setAlert: "user/setAlert"
    }),
    async applyFilters() {
      this.isLoading = true;
      this.expanded = [];
      const data = {
        agectaId: this.codigoAgenteCta,
        benGrId: this.nroGrupo,
        periodo: this.periodo,
        concepto:
          this.conceptoSelected != null ? this.conceptoSelected.id : null,
        usuario: this.userSelected,
        fecha: this.fechaSelected
      };
      try {
        const response = await this.fetchConceptosADevengarByParams(data);
        this.customizeFiltersApplied();
        this.showFilters = false;
        this.showFiltersSelected = true;
        this.itemsPorUnicaVez = response.conceptosDevengarUnicaVez;
        this.itemsPorPeriodo = response.conceptosDevengarPorPeriodo;
        this.isLoading = false;
      } catch (error) {}
    },
    async setSelects() {
      const conceptos = await this.getConceptos();
      this.conceptos = conceptos;
      const usuarios = await this.getUsuarios();
      this.usuarios = usuarios;
    },
    toggleModalBusquedaAgecta() {
      this.modalBusquedaAgecta = !this.modalBusquedaAgecta;
    },
    toggleModalBusquedaTitularGrupo() {
      this.modalBusquedaTitularGrupo = !this.modalBusquedaTitularGrupo;
    },
    findAgenteCta(agenteSelected) {
      this.codigoAgenteCta = agenteSelected.agenteId.toString();
    },
    findTitularGrupo(grupoSelected) {
      this.nroGrupo = grupoSelected.nroGrupo;
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_CONCEPTO_A_DEVENGAR:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_CONCEPTO_A_DEVENGAR:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_CONCEPTO_A_DEVENGAR:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_TODOS:
            this.canDeleteAll = true;
            break;
          case enums.modules.adminSistema.permissions.PROCESOS_DE_IMPORTACION:
            this.canGoToProcesosImportacion = true;
            break;
          case enums.modules.adminSistema.permissions.PROCESOS_DE_ACTUALIZACION:
            this.canGoToProcesosActualizacion = true;
            break;
          default:
            break;
        }
      });
    },
    exportExcelXUnicaVez() {
      let result = [];
      this.itemsPorUnicaVez.forEach(x =>
        result.push({
          ["Agente de cuenta"]: x.agectaNom,
          ["N° de grupo familiar"]: x.benGrId,
          ["Concepto"]: x.conceptoNom,
          ["Importe"]: x.importe,
          ["Período"]: x.periodo,
          ["Período a devengar"]: x.periodoDev,
          ["Comprobante orígen"]: x.comprobanteOrigenValue,
          ["Procesado en comprobante"]: x.procesado
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle del concepto a devengar por única vez"
      );
    },
    exportExcelXPeriodo() {
      let result = [];
      this.itemsPorPeriodo.forEach(x =>
        result.push({
          ["Agente de cuenta"]: x.agectaNom,
          ["N° de grupo familiar"]: x.benGrId,
          ["Concepto"]: x.conceptoNom,
          ["Importe"]: x.importe,
          ["Período desde"]: x.periDesde,
          ["Período hasta"]: x.periHasta
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle del concepto a devengar por período"
      );
    },
    banUser(num) {
      switch (num) {
        case 1:
          this.mostrarTableUnicaVez = true;
          this.mostrarTablePeriodo = false;
          break;
        case 2:
          this.mostrarTablePeriodo = true;
          this.mostrarTableUnicaVez = false;
          break;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.codigoAgenteCta) {
        this.filtersApplied.splice(0, 1, {
          key: "codigoAgenteCta",
          label: "Código de agente de cuenta",
          model: this.codigoAgenteCta
        });
      }
      if (this.nroGrupo) {
        this.filtersApplied.splice(1, 1, {
          key: "nroGrupo",
          label: "N° de grupo familiar",
          model: this.nroGrupo
        });
      }
      if (this.periodo) {
        this.filtersApplied.splice(2, 1, {
          key: "periodo",
          label: "Período",
          model: this.periodo
        });
      }
      if (this.conceptoSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "conceptoSelected.value",
          label: "Concepto",
          model: this.conceptoSelected.value
        });
      }
      if (this.userSelected) {
        this.filtersApplied.splice(4, 1, {
          key: "userSelected",
          label: "Usuario de alta",
          model: this.userSelected
        });
      }
      if (this.fechaSelected) {
        this.filtersApplied.splice(5, 1, {
          key: "fechaSelected",
          label: "Fecha de alta",
          model: this.fechaSelected
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
      this.itemsPorUnicaVez = [];
      this.itemsPorPeriodo = [];
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    openModalPorPeriodo(item) {
      this.openModalEditXPeriodo = true;
      this.conceptoADevengarXPeriodo = item;
    },
    deleteConceptoPorPeriodoModal(id) {
      this.showDeleteModal = true;
      this.titleDelete = "¿Eliminar concepto?";
      this.textDelete = "";
      this.deleteModalConceptoPorPeriodo = true;
      this.idToDelete = id;
    },
    deleteConceptoXUnicaVez(item) {
      if (item.procesado == "" && item.comprobanteOrigenValue != "") {
        this.deleteWidth = "30%";
        this.textDelete =
          "El registro fué generado desde un comprobante de cancelación. ¿Desea continuar?";
      } else if (item.procesado != "") {
        this.setAlert({
          type: "warning",
          message: "El concepto se encuentra procesado. No se puede eliminar."
        });
        return;
      } else {
        this.deleteWidth = "25%";
        this.textDelete = "";
      }
      this.idsToDelete = [];
      this.showDeleteModal = true;
      this.deleteModalDevengarUnicaVez = true;
      this.idsToDelete.push(item.interesesACobrarId);
    },
    deleteAllConceptosXUnicaVez() {
      const itemGeneradoNoProcesado = this.itemsPorUnicaVez.find(
        x => x.procesado == "" && x.comprobanteOrigenValue != ""
      );
      const itemProcesadoExist = this.itemsPorUnicaVez.find(
        x => x.procesado != ""
      );
      if (itemGeneradoNoProcesado != null && itemProcesadoExist == null) {
        this.deleteWidth = "30%";
        this.textDelete =
          "Existen registros generados desde un comprobante de cancelación. ¿Desea continuar?";
      } else if (itemProcesadoExist != null) {
        this.setAlert({
          type: "warning",
          message:
            "Existen conceptos que se encuentran procesados. No se pueden eliminar todos los registros."
        });
        return;
      } else {
        this.deleteWidth = "25%";
        this.textDelete = "";
      }
      this.idsToDelete = [];
      this.showDeleteModal = true;
      this.deleteAllConceptosBtn = true;
      this.idsToDelete = this.itemsPorUnicaVez.map(x => x.interesesACobrarId);
    },
    async confirmDelete() {
      if (this.deleteModalDevengarUnicaVez || this.deleteAllConceptosBtn) {
        const response = await this.deleteConceptosXUnicaVez(this.idsToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.deleteAllConceptosBtn = false;
          this.deleteModalDevengarUnicaVez = false;
          this.applyFilters();
        }
      } else {
        const response = await this.deleteConceptoPorPeriodo(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.deleteModalConceptoPorPeriodo = false;
          this.itemsPorUnicaVez = [];
          this.itemsPorPeriodo = [];
          this.applyFilters();
        }
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    openModalConceptXUnicaVez(item) {
      this.openModalEditDevengarUnicaVez = true;
      this.conceptoADevengarXUnicaVez = item;
    },
    closeAndReload() {
      this.openModalEditDevengarUnicaVez = false;
      this.openModalEditXPeriodo = false;
      if (this.isFormValid) this.applyFilters();
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    goToProcesosImportacion() {
      this.$router.push({
        name: "ProcesosImportacion"
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    },
    goToProcesosActualizacion() {
      this.$router.push({
        name: "ProcesosActualizacion"
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.my-row {
  max-height: 80px;
}
.v-toolbar__content {
  height: 40px !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.icon-readonly {
  cursor: context-menu;
}
.my-tooltip {
  white-space: pre-line;
}
::v-deep label {
  margin-bottom: 0;
}
.no-upper-case {
  text-transform: none;
}
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
</style>
