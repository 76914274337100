<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="primary--text">{{ title }}</span>
      </v-card-title>
      <v-row v-show="busquedaAfiliado"> </v-row>
      <v-form
        v-model="searchValid"
        ref="grupo-familiar-filter"
        id="grupo-familiar-filter"
        @submit.prevent="filterAfiliados()"
      >
        <v-row class="p-3 pb-0">
          <v-col cols="12" sm="12" md="3" class="py-0">
            <v-text-field
              v-model="afiliadoSearchDocumento"
              label="N° de documento"
              dense
              clearable
              v-mask="'########'"
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="py-0">
            <v-text-field
              v-model="afiliadoSearchApellido"
              label="Apellido/s"
              dense
              :rules="[rules.maxLength(afiliadoSearchApellido, 50)]"
              clearable
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="3" class="py-0">
            <v-text-field
              v-model="afiliadoSearchNombre"
              label="Nombre/s"
              dense
              :rules="[rules.maxLength(afiliadoSearchNombre, 50)]"
              clearable
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="py-1 text-left">
            <v-btn color="primary" type="submit" form="grupo-familiar-filter">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-form
        v-model="isFormValid"
        ref="selectAfiliado-form"
        id="selectAfiliado-form"
        @submit.prevent="selectAfiliado()"
      >
        <v-row>
          <v-col>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              v-if="filterApplied"
              :items="gruposFamiliaresResult"
              :loading="loading"
              dense
              show-select
              single-select
              v-model="grupoSelected"
              @input="selectedAfiliado()"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-col cols="2">
            <v-btn outlined @click="closeModal()">
              Volver
            </v-btn>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-btn
              color="primary"
              type="submit"
              ref="selectAfiliado-form"
              :disabled="grupoSelected.length == 0"
            >
              Seleccionar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "BuscarTitularGrupo",
  props: {},
  directives: { mask },
  data() {
    return {
      title: "Búsqueda de afiliado",
      rules: rules,
      isFormValid: false,
      filtersApplyed: [],
      gruposLoading: false,
      periodoDesde: null,
      periodoHasta: null,
      relacionSelected: null,
      tiposDeRelacion: [],
      grupoSelected: [],
      grupoFamiliarARelacionar: null,
      gruposFamiliaresResult: [],
      grupoTieneTitular: false,
      dataTableHeight: null,
      searchValid: false,
      numeroGrupoFamiliar: null,
      afiliadoSearchNombre: null,
      afiliadoSearchDocumento: null,
      afiliadoSearchApellido: null,
      grupoAutocompleteInput: null,
      filterApplied: false,
      banderaGrupoSelected: false,
      allowOverflow: false,
      busquedaIcon: "mdi-menu-down",
      busquedaAfiliado: true,
      loading: false,
      headers: [
        {
          text: "Afiliado",
          align: "start",
          sortable: true,
          value: "value"
        },
        {
          text: "N° de documento",
          sortable: false,
          value: "doc"
        },
        {
          text: "N° de grupo familiar",
          sortable: false,
          value: "nroGrupo"
        }
      ]
    };
  },
  mounted() {
    this.setTiposDeRelacion();
    if (this.benAgectaId > 0) {
      this.banderaGrupoSelected = true;
      this.tieneTitularCheck();
      this.busquedaAfiliado = true;
      this.customizeFiltersApplied();
      this.setGrupoFamiliar();
    }
  },
  watch: {
    gruposFamiliaresResult(val) {
      if (val.length >= 4) {
        this.dataTableHeight = "10rem";
      } else if (val.length >= 2) {
        this.dataTableHeight = "7rem";
      } else {
        this.dataTableHeight = "5rem";
      }
    }
  },
  methods: {
    ...mapActions({
      getRelacionGrupoFamiliarById: "agenteCuenta/getRelacionGrupoFamiliarById",
      getTipoRelacion: "agenteCuenta/getTipoRelacion",
      fetchAfiliadosByQuery: "aportes/fetchAfiliadosByQuery",
      saveRelacionGrupoFamiliar: "agenteCuenta/saveRelacionGrupoFamiliar",
      fetchAfiliadosByParams: "agenteCuenta/fetchAfiliadosByParams",
      setAlert: "user/setAlert"
    }),
    async setTiposDeRelacion() {
      this.tiposDeRelacion = await this.getTipoRelacion();
    },
    tieneTitularCheck() {
      if (this.titularNom === null && this.titularDni === null) {
        this.banderaGrupoSelected = false;
        this.grupoTieneTitular = true;
        this.setAlert({
          type: "info",
          message: "El grupo familiar no tiene un titular activo"
        });
      }
    },
    selectAfiliado() {
      this.$emit("toggleModalBusquedaTitularGrupo");
      this.$emit("findTitularGrupo", this.grupoSelected[0]);
    },
    closeModal() {
      this.$emit("toggleModalBusquedaTitularGrupo");
    },
    // metodos grupos familiares filter
    async filterAfiliados() {
      if (
        this.afiliadoSearchNombre != null ||
        this.afiliadoSearchDocumento != null ||
        this.afiliadoSearchApellido != null
      ) {
        this.loading = true;
        this.customizeFiltersApplied();
        const data = {
          apellido: this.afiliadoSearchApellido,
          dni: parseInt(this.afiliadoSearchDocumento),
          nombre: this.afiliadoSearchNombre
        };
        try {
          this.filterApplied = true;
          const response = await this.fetchAfiliadosByParams(data);
          this.gruposFamiliaresResult = response;
          this.banderaGrupoSelected
            ? (this.grupoSelected = this.gruposFamiliaresResult)
            : [];
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];

      //  chequea si ya selecciono un afiliado en el data table
      if (this.grupoSelected.length > 0) {
        this.filtersApplyed.splice(0, 1, {
          key: "value",
          label: "Afiliado",
          model: this.grupoSelected[0].value.toUpperCase()
        });
        // aca chequea si todavia no selecciono afiliado pero escondio el modal de los filtros
      } else {
        if (this.afiliadoSearchDocumento) {
          this.filtersApplyed.splice(0, 1, {
            key: "dni",
            label: "N° de documento",
            model: this.afiliadoSearchDocumento
          });
        }

        if (this.afiliadoSearchNombre) {
          this.filtersApplyed.splice(1, 1, {
            key: "nombre",
            label: "Nombre",
            model: this.afiliadoSearchNombre.toUpperCase()
          });
        }
        if (this.afiliadoSearchApellido) {
          this.filtersApplyed.splice(2, 1, {
            key: "apellido",
            label: "Apellido",
            model: this.afiliadoSearchApellido.toUpperCase()
          });
        }
      }
    },
    selectedAfiliado() {
      if (this.grupoSelected.length > 0) {
        this.numeroGrupoFamiliar = this.grupoSelected[0].nroGrupo;
        this.banderaGrupoSelected = false;
      }
    },
    selectTitularGrupo() {
      this.$emit("toggleModalBusquedaTitularGrupo");
      this.$emit("findTitularGrupo", this.grupoSelected[0]);
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
