var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"type":"text","outlined":"","dense":"","clearable":"","hint":"Código del agente","persistent-hint":"","label":"Agente de cuenta","rules":_vm.rules.required,"autocomplete":"off"},model:{value:(_vm.codigoAgenteCta),callback:function ($$v) {_vm.codigoAgenteCta=$$v},expression:"codigoAgenteCta"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.searchAgenteBtn},on:{"click":function($event){return _vm.findAgenteCta()}}},[_vm._v(" Buscar ")])],1),_c('v-col',{staticClass:"py-0 pl-8",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","fab":"","small":"","color":"primary","disabled":_vm.searchAgenteBtn},on:{"click":function($event){return _vm.toggleModalBusquedaAgecta()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.searchIcon))])],1)]}}])},[_c('span',[_vm._v("Buscar por otro")])])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"6"}},[_c('v-autocomplete',{class:_vm.conceptoADevengarXPeriodo != null
                  ? 'theme--light.v-input input'
                  : '',attrs:{"outlined":"","dense":"","disabled":_vm.conceptoADevengarXPeriodo != null,"label":"Titular del grupo","item-text":"value","item-value":"id","autocomplete":"on","rules":_vm.rules.required,"items":_vm.gruposFamiliares},model:{value:(_vm.grupoFamiliarSelected),callback:function ($$v) {_vm.grupoFamiliarSelected=$$v},expression:"grupoFamiliarSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.conceptos,"label":"Concepto","item-text":"value","rules":_vm.rules.required,"item-value":"id","outlined":"","dense":""},model:{value:(_vm.conceptoSelected),callback:function ($$v) {_vm.conceptoSelected=$$v},expression:"conceptoSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('currency-input',{attrs:{"label":"Importe","oulined":"","dense":"false","outlined":"false","options":_vm.currencyOptions,"rules":_vm.rules.required},model:{value:(_vm.importe),callback:function ($$v) {_vm.importe=$$v},expression:"importe"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],ref:"periodoDesde",attrs:{"label":"Período desde","outlined":"","dense":"","autocomplete":"not","rules":_vm.rules.required.concat(
                  _vm.rules.periodoYyyyMm,
                  _vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)
                ),"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodoDesde),callback:function ($$v) {_vm.periodoDesde=$$v},expression:"periodoDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],ref:"periodoHasta",attrs:{"label":"Período hasta","outlined":"","dense":"","autocomplete":"not","rules":_vm.rules.required.concat(
                  _vm.rules.periodoYyyyMm,
                  _vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)
                ),"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodoHasta),callback:function ($$v) {_vm.periodoHasta=$$v},expression:"periodoHasta"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1),(_vm.modalBusquedaAgecta)?_c('v-dialog',{attrs:{"max-width":"65%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalBusquedaAgecta($event)}},model:{value:(_vm.modalBusquedaAgecta),callback:function ($$v) {_vm.modalBusquedaAgecta=$$v},expression:"modalBusquedaAgecta"}},[_c('BuscarAgenteCuenta',{on:{"toggleModalBusquedaAgecta":_vm.toggleModalBusquedaAgecta,"findAgenteCta":_vm.findAgenteCta}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }